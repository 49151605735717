import {inject } from 'aurelia-framework';
import { ProfileService } from '../../../../shared/services/profielservice';
import { JwtService } from '../../../../shared/services/jwtservice';
import { DialogController } from 'aurelia-dialog';
import flatpickr from "flatpickr";
import {ValidationControllerFactory, ValidationRules} from 'aurelia-validation';
//import Scrollbar from 'smooth-scrollbar';
import {config} from "../../../../shared/services/config";

@inject(DialogController,ProfileService,JwtService,ValidationControllerFactory)
export class Editskillcomponent {
    showCertificationFields = false;
    showRenivalFields = false;
    showOtherFields = false;
    options = [];
    optionss = [];
    Skill = '';
    CertificationName = '';
    selectedOption = null;
    certification = [];
    selectedCertification = null;
    DocumentName='';
    viewfile = true;
    static inject = [Element];
    isDragging = false;
    dialog;
    constructor(controller,ProfileService,JwtService,controllerFactory) {
        this.controller = controller;
        this.profileService=ProfileService;
        this.jwtService= JwtService;
        this.Skill = '';
        this.CertificationName = '';
        this.controllerFactory = controllerFactory.createForCurrentScope();
        ValidationRules.customRule(
          'validEmails',
          (value, obj) => {
             if (typeof value !== 'string') {
                return false;
             }
             const emails = value.split(',');
              const emailRegex = /^[^\s@]+@[^\s@]+\.(com|co|in|org|cc|net)$/;
             const invalidEmails = [];
             for (const email of emails) {
                const trimmedEmail = email.trim();
                if (!emailRegex.test(trimmedEmail)) {
                   invalidEmails.push(trimmedEmail);
                }
             }
             if (invalidEmails.length > 0) {
                obj.emailsErrors = ['Invalid email address(es)'];
                return false;
             }
             if (emails.length > 1 && value.indexOf(',') === -1) {
                obj.emailsErrors = ['Email(s) should be separated by commas'];
                return false;
             }
             obj.emailsErrors = [];
             return true;
          },
          '${$displayName} is invalid.',
          (obj) => ({
             obj
          })
       );
        ValidationRules.customRule(
            'dateError',
            () =>{
                if(this.differenceError === true){
                    return false;
                }else{
                    return true;
                }
            },
            'Valid from should be less than valid to'
        );
        ValidationRules
            .ensure('Skill').required().withMessage("Skill is required")
            .ensure('SkillRating').required().withMessage("Rating is required")
            .ensure('SkillAcquiredDate').required().withMessage("Skill acquired date is required")
            .ensure('CertificationName').required().withMessage("Certification name is required")
            .ensure('ValidFrom').required().withMessage("Valid from  date is required")
            .ensure('ValidTo')
            .satisfiesRule('dateError')
            .ensure('NotifyEmployee')
            .satisfies((value, object) => {
                return value || object.NotifyHR || object.NotifyRM || object.NotifyOther;
            })
            .withMessage('Please select at least one notify field.')
            .ensure('EmailIDs').required().withMessage('Email id(s) field is required')
            .satisfiesRule('validEmails').withMessage('Invalid email address(es) and Email(s) should be separated by commas')
            .on(this);
    }
    async bind() {
        setTimeout(()=>{       
          Scrollbar.init(document.querySelector('.editskill-scrollbar'));
        },100)
      }
      activate(data){
        this.employeeskills =data;
        this.PersonSkillId = this.employeeskills.PersonSkillId;
        this.Skill = this.employeeskills.Skill;
        this.Experience = this.employeeskills.YearofExperience;
        this.SkillRating = this.employeeskills.SkillRating;
        this.SkillAcquiredDate = this.employeeskills.SkillAcquired;
        this.SkillLastUsed = this.employeeskills.SkillLastUsed;
        this.havecertification = this.employeeskills.CertificationIND;
        if( this.havecertification === true){
            this.showCertificationFields = true;
        }else if(this.havecertification === false){
            this.showCertificationFields = false;
        }
        this.CertificationName = this.employeeskills.CertificationName;
        this.ValidFrom = this.employeeskills.ValidFrom;
        this.ValidTo = this.employeeskills.ValidTo;
        this.IsCertificationRenewable = this.employeeskills.IsCertificationRenewable;
        if(this.IsCertificationRenewable === true){
            this.showRenivalFields = true;
        }else  if(this.IsCertificationRenewable === false){
            this.showRenivalFields = false;
        }
        this.RemidDaysBeforeExpiryDate = this.employeeskills.RemidDaysBeforeExpiryDate;
        this.NotifyEmployee = this.employeeskills.NotifyEmpInd;
        this.NotifyHR = this.employeeskills.NotifyHRInd;
        this.NotifyRM = this.employeeskills.NotifyRMInd;
        this.NotifyOther = this.employeeskills.NotifyOtherInd;
        if(this.NotifyOther === true){
            this.showOtherFields = true;
        }else  if(this.NotifyOther === false){
            this.showOtherFields = false;
        }
        this.EmailIDs = this.employeeskills.NotifyOtherEmailIDs;
        this.uploadfile = this.employeeskills.UploadDocuments;
        if (this.employeeskills.UploadDocuments) {
            this.file = this.employeeskills.UploadDocuments;
            this.DocumentName = this.employeeskills.UploadDocuments; // Set the desired file name
            this.DocumentNameDisplay = this.employeeskills.DocumentNameDisplay; // Set the desired file name
        }
        this.comments = this.employeeskills.Comments;
    }
    attached(){
        this.ValidFromToflatpicker();
        flatpickr(this.LatDatePicker, {
            closeOnSelect:true,
            dateFormat: 'd-m-Y',
            minDate: null,
            maxDate: null,
            defaultDate: this.dateValue,
            enableTime: false,
            enableSeconds: false,
            time_24hr: false,
            minuteIncrement: 1,
            hourIncrement: 1,
            timeFormat: 'h:i K',
            clickOpens:false,
            onClose: function (selectedDates, dateStr, instance) {
                this.SkillLastUsed=dateStr;
            }
        });

        this.lastdateIcon.addEventListener('click', () => {
            this.LatDatePicker._flatpickr.open();
        });
        flatpickr(this.AcquireddatePicker, {
            closeOnSelect:true,
            dateFormat: 'd-m-Y',
            minDate: null,
            maxDate: null,
            defaultDate: this.dateValue,
            enableTime: false,
            enableSeconds: false,
            time_24hr: false,
            minuteIncrement: 1,
            hourIncrement: 1,
            timeFormat: 'h:i K',
            clickOpens:false,
            onClose: function (selectedDates, dateStr, instance) {
                this.SkillAcquiredDate=dateStr;
            }
        });

        this.acquiredIcon.addEventListener('click', () => {
            this.AcquireddatePicker._flatpickr.open();
        });
        this.profileService.GetSkillsRatingDropdown()
            .then(data => {
                this.options= data;
            });
    }
    cancel() {
        this.controller.cancel();
    }
    handleKeyDown(event) {
        if (event.key === 'Enter') {
            event.preventDefault();
            event.stopPropagation();
            return false;
        }
        return true;
    }

    async onSkillsSelected() {
        const trimmedSkill = this.Skill.trim(); // Remove leading and trailing spaces
        this.searchedskill = this.Skill;
        if (trimmedSkill.length > 0) {
            // Non-empty input, proceed to open the dropdown and perform filtering
            this.showEmpDropdown = true;
            try {
                const response = await this.profileService.GetSkillsDropdown(this.searchedskill);
                const data = await response;
                this.optionss = Object.entries(data).map(([key, value]) => ({ value, key }));
                const searchValue = trimmedSkill.toLowerCase();
                this.filteredOptions = this.optionss.filter(option => {
                    const optionValue = option.value.toLowerCase();
                    return optionValue.includes(searchValue);
                });
            } catch (error) {
                // Handle errors
            }
        } else {
            // Empty input or only spaces, don't open the dropdown
            this.showEmpDropdown = false;
            this.filteredOptions = [];
        }
    }
    selectSkill(option) {
        this.selectedOption = option;
        this.Skill = option.value;
        this.showEmpDropdown = false;
    }
    async  onCerticationSelected() {
        if (this.CertificationName) {
            this.showcertificationDropdown = true;
            try {
                const response = await this.profileService.GetCertificationDropdown(this.CertificationName);
                const data = await response;
                this.certification = Object.entries(data).map(([key, value]) => ({ value, key }));
                this.filteredOptions = this.certification.filter(option => {
                    const searchValue = this.CertificationName.toLowerCase();
                    return option.value.toLowerCase().includes(searchValue);
                });
            } catch (error) {
            }
        } else {
            this.showcertificationDropdown = false;
            this.filteredOptions = [];
        }
    }
    selectCertification(option) {
        this.selectedCertification = option;
        this.CertificationName = option.value;
        this.showcertificationDropdown = false;
    }
    async  toggleCertificationFields(checked) {
        this.showCertificationFields = checked;
        this.ValidFromToflatpicker();
    }
    ValidFromToflatpicker(){
        flatpickr(this.ValidFromDatePicker, {
            closeOnSelect: true,
            dateFormat: 'd-m-Y',
            minDate: null,
            maxDate: null,
            defaultDate: this.dateValue,
            enableTime: false,
            enableSeconds: false,
            time_24hr: false,
            minuteIncrement: 1,
            hourIncrement: 1,
            timeFormat: 'h:i K',
            clickOpens: false,
            onClose: (selectedDates, dateStr, instance) => {
                this.ValidFrom = dateStr;
            }
        });

        this.validfromIcon.addEventListener('click', () => {
            this.ValidFromDatePicker._flatpickr.open();
        });
        flatpickr(this.ValidToDatePicker, {
            closeOnSelect: true,
            dateFormat: 'd-m-Y',
            minDate: null,
            maxDate: null,
            defaultDate: this.dateValue,
            enableTime: false,
            enableSeconds: false,
            time_24hr: false,
            minuteIncrement: 1,
            hourIncrement: 1,
            timeFormat: 'h:i K',
            clickOpens: false,
            onClose: (selectedDates, dateStr, instance) => {
                this.ValidTo = dateStr;
            }
        });
        this.validtoIcon.addEventListener('click', () => {
            this.ValidToDatePicker._flatpickr.open();
        });
    }

    toggleRenivalFields(checked) {
        this.showRenivalFields = checked;
    }
    toggleOtherFields(checked){
        this.showOtherFields = checked;
    }
    handleFileSelect(files) {
        this.viewfile=false;
        const file = files[0];
        this.file = file;
        let reader = new FileReader();
        reader.readAsDataURL(file);
        this.DocumentName = file.name;
        reader.onload = () => {
            this.file = reader.result;
        };
    }
    downloadFile(viewPath) {
        const downloadUrl = config.api_img_url  + viewPath;
        const link = document.createElement('a');
        link.href = downloadUrl;
        link.target = '_blank';
        link.download = downloadUrl;
        link.click();
    }
    openFileDialog() {
        document.getElementById('attachments').click();
    }
    removeFile() {
        this.file = null;
        this.DocumentName = '';
        const fileInput = document.getElementById('attachments');
        if (fileInput) {
            fileInput.value = ''; // Clear the file input value
        }
    }
    startDragging(event) {
        if (event.button === 0) {
            this.isDragging = true;
            this.offsetX = event.clientX - this.dialogElement.getBoundingClientRect().left;
            this.offsetY = event.clientY - this.dialogElement.getBoundingClientRect().top;

            window.addEventListener('mousemove', this.drag);
            window.addEventListener('mouseup', this.stopDragging);
        }
    }

    drag = (event) => {
        if (this.isDragging) {
            const x = event.clientX - this.offsetX;
            const y = event.clientY - this.offsetY;

            this.dialogElement.style.left = `${x}px`;
            this.dialogElement.style.top = `${y}px`;
        }
    }

    stopDragging = () => {
        this.isDragging = false;
        window.removeEventListener('mousemove', this.drag);
        window.removeEventListener('mouseup', this.stopDragging);
    }
    calculateDateDifference() {
        if (this.ValidFrom && this.ValidTo) {
            const fromParts = this.ValidFrom.split('-');
            const toParts = this.ValidTo.split('-');
            const fromDate = new Date(fromParts[2], fromParts[1] - 1, fromParts[0]);
            const toDate = new Date(toParts[2], toParts[1] - 1, toParts[0]);
            const differenceInTime = toDate.getTime() - fromDate.getTime();
            const differenceInDays = Math.ceil(differenceInTime / (1000 * 3600 * 24));
            const years = Math.floor(differenceInDays / 365);
            const months = Math.floor((differenceInDays % 365) / 30);
            const days = differenceInDays % 30;
            const formattedYears = years > 0 ? `${years} Year(s)` : '';
            const formattedMonths = months > 0 ? `${months} Month(s)` : '';
            const formattedDays = days > 0 ? `${days} Day(s)` : '';
            this.dateDifference = `${formattedYears} ${formattedMonths} ${formattedDays}`;
            if (differenceInDays < 1) {
                this.differenceError = true;
            }else{
                this.differenceError = false;
            }
        }
    }
    save() {
      const refreshEvent = new CustomEvent('refreshEditSkill');
      const SkillRating = document.getElementById("ratings").value;
      let NotifyEmployee = false;
      let NotifyHR = false;
      let NotifyRM = false;
      let NotifyOther = false;
      let havecertification = false;
      let IsCertificationRenewable = false;
      if(this.employeeskills.NotifyEmpInd === true){
          NotifyEmployee = true;
      }
      if(this.employeeskills.NotifyHRInd === true){
          NotifyHR = true;
      }
      if(this.employeeskills.NotifyOtherInd === true){
          NotifyOther = true;
      }
      if(this.employeeskills.CertificationIND === true){
          havecertification = true;
      }
      if(this.employeeskills.IsCertificationRenewable === true){
          IsCertificationRenewable = true;
      }
      if(this.employeeskills.NotifyRMInd){
          NotifyRM = true;
      }

      this.controllerFactory.validate()
          .then(result => {
              if (result.valid) {
                  const empid = this.jwtService.getEmployeeId();
                  if (this.havecertification === true) {
                      if (this.CertificationName === '' || this.CertificationName.length < 0) {
                          this.certificatenameerror = 'Certification name is required';
                          const errorMessages = document.querySelectorAll(".error-messages");
                          if (errorMessages.length > 0) {
                              errorMessages[0].scrollIntoView({ behavior: "smooth" });
                          }
                      }
                      if (this.ValidFrom == null || this.ValidFrom === '' || this.CertificationName === '' || this.CertificationName.length < 1) {
                          if (this.ValidFrom == null || this.ValidFrom === '') {
                              this.validfromerror = 'Valid from  date is required';
                              const errorMessages = document.querySelectorAll(".error-messages");
                              if (errorMessages.length > 0) {
                                  errorMessages[0].scrollIntoView({ behavior: "smooth" });
                              }
                          }else {
                              this.validfromerror = "";
                          }
                          if (this.CertificationName === '' || this.CertificationName.length < 0) {
                              this.certificatenameerror = 'Certification name is required';
                              const errorMessages = document.querySelectorAll(".error-messages");
                              if (errorMessages.length > 0) {
                                  errorMessages[0].scrollIntoView({ behavior: "smooth" });
                              }

                          }else{
                              this.certificatenameerror = "";
                          }
                          return;
                      }
                  }
                      let data = {
                          EmployeeID: empid,
                          Skill: this.Skill,
                          SkillRating: SkillRating,
                          YearofExperience: this.Experience,
                          CertificationName: this.CertificationName,
                          Comments: this.comments,
                          CertificationIND: this.havecertification,
                          IsCertificationRenewable: this.IsCertificationRenewable,
                          UploadDocuments: this.file,
                          DocumentName: this.DocumentName,
                          SkillAcquired: this.SkillAcquiredDate,
                          SkillLastUsed: this.SkillLastUsed,
                          DocumentPath: this.DocumentPath,
                          NotifyEmpInd: this.NotifyEmployee,
                          NotifyOtherInd: this.NotifyOther,
                          NotifyHRInd: this.NotifyHR,
                          NotifyRMInd: this.NotifyRM,
                          NotifyOtherEmailIDs: this.EmailIDs,
                          PersonSkillId: this.PersonSkillId,
                          ValidFrom: this.ValidFrom,
                          ValidTo: this.ValidTo,
                          RemidDaysBeforeExpiryDate: this.RemidDaysBeforeExpiryDate,
                      };
                  const SkillValidatecheck = {
                      EmployeeID: empid,
                      Skill: this.Skill,
                      PersonSkillId: this.PersonSkillId,
                  };
                  this.profileService.SkillAvoidDuplicate(SkillValidatecheck).then(skill => {
                      if (skill === 1) {
                          this.errorMessage = true;
                          this.errorMessage = 'Skill already exists.';
                          const errorMessages = document.querySelectorAll(".error-messages");
                          if (errorMessages.length > 0) {
                              errorMessages[0].scrollIntoView({ behavior: "smooth" });
                          }
                          setTimeout(() => {
                              this.errorMessage = null
                          }, 5000);
                      }
                      else {
                          this.profileService.SaveEmployeeSkills(data)
                              .then(() => {
                                  this.controller.cancel();
                                  this.profileService.GetEmployeeSkills(empid)
                                      .then(data => {
                                          this.employeeskills = data;
                                          document.dispatchEvent(refreshEvent); // Trigger the custom event
                                      });
                              });
                      }

                  });

              } else{
                  const errorMessages = document.querySelectorAll(".error-messages");
                  if (errorMessages.length > 0) {
                      errorMessages[0].scrollIntoView({ behavior: "smooth" });
                  }}
          });
    }
}
