import {inject,bindable, bindingMode} from 'aurelia-framework';
import { DialogController } from 'aurelia-dialog';
import flatpickr from 'flatpickr';
import { ProfileService } from '../../../../../shared/services/profielservice';
import { JwtService } from '../../../../../shared/services/jwtservice';
import {ValidationControllerFactory, ValidationRules} from 'aurelia-validation';

@inject(DialogController,ProfileService,JwtService,ValidationControllerFactory)
export class Editfamilycomponent {
    @bindable({ defaultBindingMode: bindingMode.twoWay }) dateValue;
    options=[];
    lifetypes=[];
    isSearchActive = false;
    searchQuery = '';
    dropdownOptions = [];
    showEmpDropdown = true;
    organization = false;
    static inject = [Element];
    isDragging = false;
    dialog;
    constructor(controller,ProfileService,JwtService,controllerFactory) {
        this.controller = controller;
        this.profileService=ProfileService;
        this.jwtService= JwtService;
        this.dropdownOptions = [];
        this.controllerFactory = controllerFactory.createForCurrentScope();
        ValidationRules
            .ensure('Name').required()
            .ensure('searchQuery').required()
            .ensure('Relationship').required()
            .ensure('LifeEventType').required().withMessage("Life event is required")
            .ensure('DateofBirth').required().withMessage("Date of birth is required")
            .on(this);
    }
   
    activate(data){
        this.employeelife = data;
        this.Relationship= this.employeelife.EmployeeDependentConfigID;
        this.Name = this.employeelife.Name;
        this.DependentPersonID =this.employeelife.DependentPersonID;
        this.LifeEventType = this.employeelife.LifeEventType;
        this.DateofBirth = this.employeelife.DateofBirth;
        this.dependent = this.employeelife.DependentIND;
        this.organization = this.employeelife.SameOrganizationIND;
        if(this.organization === true){
            this.toggleSearchBar();
        }
        this.personID = data.PersonDependantID;
        const date = new Date(data.DateofBirth);
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const year = date.getFullYear();
        this.dateValue=`${day}-${month}-${year}`;
    }
    attached() {
        const dialog = document.querySelector("ai-dialog");

        dialog.addEventListener("mousedown", (event) => {
            if (event.target.classList.contains("modal-popup")) {
                event.preventDefault();
                dialog.classList.add("dragging");
            }
        });
        flatpickr(this.datePicker, {
            closeOnSelect:true,
            dateFormat: 'd-m-Y',
            minDate: null,
            maxDate: null,
            defaultDate:this.DateofBirth,
            enableTime: false,
            enableSeconds: false,
            time_24hr: false,
            minuteIncrement: 1,
            hourIncrement: 1,
            timeFormat: 'h:i K',
            clickOpens:false,
            onClose: function (selectedDates, dateStr, instance) { 
                this.DateofBirth = dateStr;
            }
        });
        this.calendarIcon.addEventListener('click', () => {
            this.datePicker._flatpickr.open();
        });
        this.isLoading = true;
        setTimeout(() => {
            this.isLoading = false;
        }, 3000);
        this.profileService.EmployeeFamilyRelationship()
            .then(data => {
                this.options= data;
            });
        this.profileService.EmployeeFamilyLifeeventtypes()
            .then(data => {
                this.lifetypes= data;
            });
        this.toggleSearchBar();

    }
    EditFamilyMember(id) {
        const empid = this.jwtService.getEmployeeId();
        this.profileService.GetFamilyMember(empid).then(data=>{
            this.employeefamily=data;
            this.Name = this.employeefamily.Name;
            data.forEach(item=> {
            });
        });
    }
    toggleSearchBar(checked) {
        if(this.organization === true){
            this.isSearchActive = true;
            this.searchQuery = this.Name;
        }else if(this.organization === false){
            this.isSearchActive = false;
            this.Name= this.employeelife.Name;
        }
    }
    async onEmployeeSelected() {
        if (this.searchQuery) {
            this.showEmpDropdown = true;
            try {
                const response = await this.profileService.EmployeeNamesForDropdown(this.searchQuery);
                const data = await response;
                const filterOption = this.searchQuery.toLowerCase();
                this.dropdownOptions = data.filter(option => option.EmployeeName.toLowerCase().includes(filterOption));
                if (this.dropdownOptions.length === 0) {
                    const errorInfo = {
                        error: {
                            message: "Please select valid employee"
                        }
                    };
                    this.NameError = [errorInfo];
                } else {
                    this.NameError = [];
                }
            } catch (error) {
                console.error(error);
            }
        } else {
            this.showEmpDropdown = false;
            this.dropdownOptions = [];
        }
    }
    selectEmployee(EmpOption) {
        const modifiedOption = { ...EmpOption, ID: EmpOption.EmployeeID };
        this.employeeName = EmpOption.EmployeeID;
        this.searchQuery = modifiedOption.EmployeeName;
        this.showEmpDropdown = false;
        this.selectedEmployee = modifiedOption;
    }
    cancel() {
        this.controller.cancel();
    }
    startDragging(event) {
        if (event.button === 0) {
            this.isDragging = true;
            this.offsetX = event.clientX - this.dialogElement.getBoundingClientRect().left;
            this.offsetY = event.clientY - this.dialogElement.getBoundingClientRect().top;

            window.addEventListener('mousemove', this.drag);
            window.addEventListener('mouseup', this.stopDragging);
        }
    }

    drag = (event) => {
        if (this.isDragging) {
            const x = event.clientX - this.offsetX;
            const y = event.clientY - this.offsetY;

            this.dialogElement.style.left = `${x}px`;
            this.dialogElement.style.top = `${y}px`;
        }
    }

    stopDragging = () => {
        this.isDragging = false;
        window.removeEventListener('mousemove', this.drag);
        window.removeEventListener('mouseup', this.stopDragging);
    }
    async save(){
        const refreshEvent = new CustomEvent('refreshEditFamily');
        event.preventDefault();
        const DateOfBirth = document.getElementById('dob').value;
        let checkedorganization = false;
        let checkeddpendent = false;
        let lifeeventdate = "00-00-0001";
        let EmployeeName;
        let EmployeeID;
        if( this.organization === true) {
            EmployeeID = this.employeeName;
            if(EmployeeID ==null)
            {
                this.EmployeeName = this.Name;
            }
            else{
            checkeddpendent = true;
            await this.profileService.GetEmployeeNamesForGrid(EmployeeID)
                .then(data => {
                    this.EmployeeName = data.EmployeeName;
                });
            }
        }
        else{
            this.EmployeeName = this.Name;
        }
        if(this.employeelife.dependent=== true){
            let checkeddpendent = true
        }
        if(this.employeelife.LifeEventTypeDate){
            let lifeeventdate = true
        }
        const empid = this.jwtService.getEmployeeId();
        // var dateParts = this.DateofBirth.split("-");
        //    var formattedDate = dateParts[1] + "-" + dateParts[0] + "-" + dateParts[2];
        //    console.log(this.DateofBirth); 
        //       console.log(formattedDate);
        this.controllerFactory.validate()
            .then(result => {
                if (result.valid) {
                    let data = {
                        EmployeeID:empid,
                        Name:this.EmployeeName,
                        EmployeeDependentConfigID:this.Relationship,
                        DateofBirth:this.DateofBirth,
                        LifeEventType:this.LifeEventType,
                        LifeEventTypeDate:lifeeventdate,
                        SameOrganizationIND:this.organization,
                        DependentIND:checkeddpendent,
                        PersonDependantID:this.employeelife.PersonDependantID,
                        Relationship:this.employeelife.Relationship,
                    };
                    const EmployeeFamilyValidatecheck={
                        EmployeeID:empid,
                        Name:this.EmployeeName,
                        PersonDependantID:this.employeelife.PersonDependantID,
                    };
                    this.profileService.EmployeeNameAvoidDuplicate(EmployeeFamilyValidatecheck).then(employeeName => {
                        if (employeeName === 1) {
                            this.errorMessage=true;
                            this.errorMessage = 'Name already exists.';
                            setTimeout(()=>{this.errorMessage=null},5000);
                        }
                        else{
                            this.profileService.EmployeeFamilyMemberSave(data)
                                .then(() => {
                                    this.controller.cancel();
                                    this.profileService.GetFamilyMember(empid)
                                .then(data => {
                                    this.employeefamily = data;
                                    document.dispatchEvent(refreshEvent); // Trigger the custom event
                                });
                        });
                             }
                         });
                }
            });

        }
}
