import {inject } from 'aurelia-framework';
import { ProfileService } from '../../../shared/services/profielservice';
import { JwtService } from '../../../shared/services/jwtservice';
import {DialogService} from 'aurelia-dialog';
import {Editorientation} from "./editorientation/editorientation";
import Scrollbar from 'smooth-scrollbar';
import { Router } from 'aurelia-router';
import {config} from "../../../shared/services/config";
import {LoginService} from "../../../shared/services/loginservice";

@inject(ProfileService,JwtService,DialogService,Router,LoginService)

export class Orientation {
    pageSize = config.grid_PazeSize;


    constructor(ProfileService,JwtService,DialogService,router,loginService) {
        this.profileService = ProfileService;
        this.jwtService = JwtService;
        this.dialogService = DialogService;
        this.router = router;
        this.loginService = loginService;
        this.empid = this.jwtService.getEmployeeId();
    }

    async bind() {
        setTimeout(()=>{       
          Scrollbar.init(document.querySelector('.orientation-scrollbar'));
        },1000)
      }
    nameLength(row) {
        return row.Title.length;
    }

    dateSort(a, b, sortOrder) {
        let date1 = new Date(a.registered);
        let date2 = new Date(b.registered);

        if (date1 === date2) {
            return 0;
        }

        if (date1 > date2) {
            return 1 * sortOrder;
        }

        return -1 * sortOrder;
    }
  async  attached(params){

       
        const id = this.router.currentInstruction.queryParams.id;
        //console.log(id);
        if(id != null && id !='' && id !=undefined)
        {
            this.empid=id;
        }
        else{

            this.empid=this.jwtService.getEmployeeId();

        }
        const orientation = await this.profileService.Orientationtabledata(this.empid);
        console.log( orientation);
          this.orientation = orientation;


    }

    popupEditOrientation(Editdata){
        this.dialogService.open({viewModel : Editorientation, model: Editdata});
    }
}
