import {inject } from 'aurelia-framework';
import { ProfileService } from '../../../shared/services/profielservice';
import { JwtService } from '../../../shared/services/jwtservice';
import flatpickr from 'flatpickr';
import 'flatpickr/dist/flatpickr.min.css';
import {DialogService} from 'aurelia-dialog';
import {ValidationControllerFactory, ValidationRules} from 'aurelia-validation';
import { AlertpopupComponent } from '../../alertpopup/alertpopupcomponent';
import { Addskillcomponent } from './addemployeeskills/addskillcomponent';
import { Editskillcomponent } from './editemployeeskills/editskillcomponent';
import Scrollbar from 'smooth-scrollbar';
import { Router } from 'aurelia-router';
import {config} from '../../../shared/services/config';
import {LoginService} from "../../../shared/services/loginservice";

@inject(ProfileService,JwtService,DialogService,ValidationControllerFactory,Router,LoginService)
export class Skills {
    pageSize = config.grid_PazeSize;
    skillmessage = false;
    deletemessage = false;

  constructor(profileService,jwtService,dialogService,controllerFactory,router,loginService) {
    this.profileService = profileService;
    this.jwtService = jwtService;
    this.dialogService = dialogService;
    this.controller = controllerFactory.createForCurrentScope();
    this.router = router;
      this.loginService = loginService;
    this.empid = this.jwtService.getEmployeeId();
  }
  async bind() {
    setTimeout(()=>{       
      Scrollbar.init(document.querySelector('.skills-scrollbar'));
    },100)
  }
activate(){
      this.employeeskills = this.GetEmployeeSkillsDataADD();
      this.employeeskills = this.GetEmployeeSkillsDataEDIT();
}
  async attached(params) {

      const id = this.router.currentInstruction.queryParams.id;
      if(id != null && id !='' && id !=undefined)
            {
                this.empid=id;
            }
            else{
                this.empid=this.jwtService.getEmployeeId();
            }

    const employeeSkillsData = await this.profileService.GetEmployeeSkills(this.empid);
    this.employeeskills = employeeSkillsData;
    document.addEventListener('refreshAddSkill', this.GetEmployeeSkillsDataADD);
    document.addEventListener('refreshEditSkill', this.GetEmployeeSkillsDataEDIT);
  }
  AddnewskillPopup(data) {
    this.dialogService.open({viewModel: Addskillcomponent, model: data});
}
EditskillPopup(data) {
    this.dialogService.open({viewModel: Editskillcomponent, model: data});
}
async deleteEmployeeSkills(data) {
  const SkillId = data.PersonSkillId;
  const result = await this.dialogService.open({
      viewModel: AlertpopupComponent,
      model: "",
  }).whenClosed(response => response);
  if (result.wasCancelled) {
      return;
  }
  await this.profileService.DeleteEmployeeSkills(SkillId, this.empid);
  const employeeSkills = await this.profileService.GetEmployeeSkills(this.empid);
  this.employeeskills = employeeSkills;
    this.Deletesucess = "Skills deleted successfully";
    this.deletemessage = true;
    setTimeout(() =>{
        this.Deletesucess = null;
    },3000)
}
GetEmployeeSkillsDataADD = () => {
       this.profileService.GetEmployeeSkills(this.empid)
            .then(data => {
                this.employeeskills = data;
                this.Skillsucess = "Skills added successfully";
                this.skillmessage = true;
                setTimeout(() =>{
                    this.Skillsucess = null;
                },3000)
            });
    };
  GetEmployeeSkillsDataEDIT = () => {
       this.profileService.GetEmployeeSkills(this.empid)
            .then(data => {
                this.employeeskills = data;
                this.Skillsucess = "Skills updated successfully";
                this.skillmessage = true;
                setTimeout(() =>{
                    this.Skillsucess = null;
                },3000)
            });
    };

}
