import {inject } from 'aurelia-framework';
import { DialogController } from 'aurelia-dialog';
import { ProfileService } from '../../../../../shared/services/profielservice';
import { JwtService } from '../../../../../shared/services/jwtservice';
import {ValidationControllerFactory, ValidationRules} from 'aurelia-validation';
import {LoginService} from "../../../../../shared/services/loginservice";
import { Router } from 'aurelia-router';

@inject(DialogController,ProfileService,JwtService,ValidationControllerFactory,Router,LoginService)
export class AddNewLanguage {
    options = [];
    languagevalidError = false;
    static inject = [Element];
    isDragging = false;
    dialog;
    constructor(controller,ProfileService,JwtService,controllerFactory,router,loginService) {
        this.controller = controller;
        this.profileService=ProfileService;
        this.jwtService = JwtService;
        this.router = router;
        this.loginService = loginService;
        this.controllerFactory = controllerFactory.createForCurrentScope();
        this.empid = this.jwtService.getEmployeeId();
        ValidationRules
            .ensure('Language').required()
            .ensure('Read').required()
            .ensure('Speak').required()
            .ensure('Write').required()
            .on(this);
    }
    async attached() {

        this.profileService.EmployeePersonalDetailsLanguage()
            .then(data => {
                this.options= Object.entries(data).map(([key, value])=>({value, key}));
            });
    }
    cancel() {
        this.controller.cancel();
    }
    validateLanguage(event) {
        const inputElement = event.target;
        const inputValue = inputElement.value;
        const onlyCharactersRegex = /^[A-Za-z\s]*$/;
        if (!onlyCharactersRegex.test(inputValue)) {
            this.languagevalidError = true;
        } else {
            this.languagevalidError = false;
        }
    }
    async checkExistingLanguage() {
        const desiredLanguage = this.Language;
        try {
            const employeeLanguageData = await this.profileService.GetEmployeeLanguage(this.empid);
            this.employeelanguage = employeeLanguageData;
            const existingLanguage = this.employeelanguage.some(lang => lang.Language === desiredLanguage);
            if (existingLanguage) {
                const errorInfo = {
                    error: {
                        message: "Language already exists."
                    }
                };
                this.LanguageError = [errorInfo];
            } else {
                this.LanguageError = [];
            }
        } catch (error) {
            console.error(error);
        }
    }
    startDragging(event) {
        if (event.button === 0) {
            this.isDragging = true;
            this.offsetX = event.clientX - this.dialogElement.getBoundingClientRect().left;
            this.offsetY = event.clientY - this.dialogElement.getBoundingClientRect().top;

            window.addEventListener('mousemove', this.drag);
            window.addEventListener('mouseup', this.stopDragging);
        }
    }

    drag = (event) => {
        if (this.isDragging) {
            const x = event.clientX - this.offsetX;
            const y = event.clientY - this.offsetY;

            this.dialogElement.style.left = `${x}px`;
            this.dialogElement.style.top = `${y}px`;
        }
    }

    stopDragging = () => {
        this.isDragging = false;
        window.removeEventListener('mousemove', this.drag);
        window.removeEventListener('mouseup', this.stopDragging);
    }
    save() {
        const refreshEvent = new CustomEvent('refreshLanguage');
        event.preventDefault();
        this.controllerFactory.validate()
            .then(result => {
                if (result.valid && !this.languagevalidError) {
                    const empid = this.jwtService.getEmployeeId();
                    let data = {
                        EmployeeID: empid,
                        Language: this.Language,
                        ReadCompetency: this.Read,
                        SpeakCompetency: this.Speak,
                        WriteCompetency: this.Write,
                    };
                    const LanguageNameValidatecheck={
                        EmployeeID: empid,
                        Language: this.Language,
                    };
                    this.profileService.LanguageNameAvoidDuplicate(LanguageNameValidatecheck).then(languagename => {
                        if (languagename === 1) {
                            this.errorMessage=true;
                            this.errorMessage = 'Language already exists.';
                            setTimeout(()=>{this.errorMessage=null},5000);
                        }

                        else {
                            this.profileService.EmployeeLanguageSave(data)
                                .then(() => {
                                    this.controller.cancel();
                                    this.profileService.GetEmployeeLanguage(empid)
                                        .then(data => {
                                            this.employeelanguage = data;
                                            document.dispatchEvent(refreshEvent); // Trigger the custom event
                                            });
                                    });
                        }
                        });
                }
            });
    }

}

