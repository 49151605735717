import {inject } from 'aurelia-framework';
import { DialogController } from 'aurelia-dialog';
import { ProfileService } from '../../../../shared/services/profielservice';
import { JwtService } from '../../../../shared/services/jwtservice';
import Scrollbar from 'smooth-scrollbar';
import {config} from "../../../../shared/services/config";


@inject(DialogController,ProfileService,JwtService)
export class Editorientation {
    pageSize = config.grid_PazeSize;
    topicsPageSize=10;
    OnlineTopic = [];
    static inject = [Element];
    isDragging = false;
    dialog;
    constructor(controller, ProfileService, JwtService) {
        this.controller = controller;
        this.profileService = ProfileService;
        this.jwtService = JwtService;
    }

    activate(viewData){
      console.log( viewData);
       this.orientation = viewData;
      this.Title=viewData.Title;
       this.Mode=viewData.Mode;
        this.ScheduleDateTime=viewData.ScheduleDateTime;
        this.Venue=viewData.Venue;
        this.ConferenceType=viewData.ConferenceType;
        this.WebMeetingVenue=viewData.WebMeetingVenue;
            this.programListID = viewData.InductionProgramListId;
    }


    async bind() {
        setTimeout(()=>{
          Scrollbar.init(document.querySelector('.orientaion-pop-scrollbar'));
        },1000)
      }



    dateSort(a, b, sortOrder) {
        let date1 = new Date(a.registered);
        let date2 = new Date(b.registered);

        if (date1 === date2) {
            return 0;
        }

        if (date1 > date2) {
            return 1 * sortOrder;
        }

        return -1 * sortOrder;
    }

    nameLength(row) {
        return row.Topic.length;
    }

    async attached(){
      const ProgramListData = await this.profileService.OrientationViewData(this.programListID);
      this.programList = await ProgramListData;
      this.OnlineTopic=this.programList.OnlineTopics;
      console.log(this.OnlineTopic.Topic);
      this.programListGrid = this.programList.InPersonTopics;
      //this.ScheduleDateTime=this.programList.ScheduleDateTime


      const LocationData= await  this.profileService.OrientationLocation(this.programListID);
      this.Location= await LocationData;
      console.log(this.Location);


    }

    cancel(){
    this.controller.cancel();
    }
    startDragging(event) {
        if (event.button === 0) {
            this.isDragging = true;
            this.offsetX = event.clientX - this.dialogElement.getBoundingClientRect().left;
            this.offsetY = event.clientY - this.dialogElement.getBoundingClientRect().top;

            window.addEventListener('mousemove', this.drag);
            window.addEventListener('mouseup', this.stopDragging);
        }
    }

    drag = (event) => {
        if (this.isDragging) {
            const x = event.clientX - this.offsetX;
            const y = event.clientY - this.offsetY;

            this.dialogElement.style.left = `${x}px`;
            this.dialogElement.style.top = `${y}px`;
        }
    }

    stopDragging = () => {
        this.isDragging = false;
        window.removeEventListener('mousemove', this.drag);
        window.removeEventListener('mouseup', this.stopDragging);
    }

}
