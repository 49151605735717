import {inject } from 'aurelia-framework';
import { ProfileService } from '../../../shared/services/profielservice';
import { JwtService } from '../../../shared/services/jwtservice';
import flatpickr from 'flatpickr';
import 'flatpickr/dist/flatpickr.min.css';
import {DialogService} from 'aurelia-dialog';
import {ValidationControllerFactory, ValidationRules} from 'aurelia-validation';
import { Editfamilycomponent } from './family/editfamily/editfamilycomponent';
import { Editlanguagecomponent } from './language/editlanguage/editlanguagecomponent';
import { AddNewLanguage } from './language/addnewlanguage/addnewlanguagecomponent';
import { Addfamilycomponent } from './family/addnewfamily/addfamilycomponent';
import { AlertpopupComponent } from '../../alertpopup/alertpopupcomponent';
import Scrollbar from 'smooth-scrollbar';
import { DialogController } from 'aurelia-dialog';
import { Router } from 'aurelia-router';
import {config} from '../../../shared/services/config';

@inject(DialogController,ProfileService,JwtService,ValidationControllerFactory,DialogService,Router)
export class Personaldetails {
    detailsmessage=false;
    MaritalStatus=[];
    personalInformation;
    pageSize = config.grid_PazeSize;
    topicsPageSize = 10;
    birthPlaceError = false;
    ssnUidError = false;
    uanError=false;
    panNumberError = false;
    LinkedinValidationMessage = false;
    twitterValidationMessage=false;
    constructor(controller,ProfileService,JwtService,ValidationControllerFactory,dialogService,router) {
        this.controller = controller;
        this.profileService = ProfileService;
        this.jwtService = JwtService;
        this.dialogService = dialogService;
        this.router = router;
        this.controllerFactory = ValidationControllerFactory.createForCurrentScope();
        this.empid = this.jwtService.getEmployeeId();
        ValidationRules.customRule(
            'dateError',
            () =>
                '${$displayName}.required'
        );
        ValidationRules
            .ensure('SSNUID').displayName("SSN / UID / Aadhar").required()
            .ensure('DateOfBirth').displayName("Date of birth").required()
            .ensure('bloodGroup').displayName("Blood group").required()
            .on(this);
    }
    async bind() {
        setTimeout(()=>{
            Scrollbar.init(document.querySelector('.language-scrollbar'));
            Scrollbar.init(document.querySelector('.family-scrollbar'));
        },1000);
        this.isLoading = true;
    }

    activate(){
        this.employeelanguage = this.GetEmployeeLanguageData();
        this.employeelanguage = this.GetEmployeeEditLanguageData();
        this.employeefamily = this.GetEmployeeFamilyData();
        this.employeefamily = this.GetEmployeeEditFamilyData();
    }
    async attached(params) {
        const id = this.router.currentInstruction.queryParams.id;
        if(id != null && id !='')
        {
            this.empid=id;
        } else{
            this.empid=this.jwtService.getEmployeeId();
        }
       
        flatpickr(this.datePickers, {
            dateFormat: 'd-m-Y',
            minDate: null,
            maxDate: null,
            defaultDate: null,
            enableTime: false,
            enableSeconds: false,
            time_24hr: false,
            minuteIncrement: 1,
            hourIncrement: 1,
            timeFormat: 'h:i K',
            onClose: function(selectedDates, dateStr, instance) {
            }
        });
        this.calendarIcons.addEventListener('click', () => {
            this.datePickers._flatpickr.open();
        });
        const bloodgroupdropdown = await this.profileService.EmployeePersonalDetailsGetBloodGroupsForDropdown();
        this.BloodGroup = bloodgroupdropdown;
        const martialStatus = await this.profileService.EmployeePersonalDetailsGetMaritalStatusForDropdown();
        this.MaritalStatus = martialStatus;
        const ethinicdropdown = await this.profileService.EmployeePersonalDetailsGetEthinicRacesForDropdown();
        this.EthinicRaces = ethinicdropdown;
        const nationalityDropdown = await this.profileService.EmployeePersonalDetailsGetNationalitiesForDropdown();
        this.Nationalities = nationalityDropdown;
        const employeePersonalDetails = await this.profileService.EmployeePersonalDetails(this.empid);
        this.personalInformation = employeePersonalDetails;
        this.PanNumber = this.personalInformation.PANNumber;
        this.SSNUID = this.personalInformation.SSNUID;
        this.DateOfBirth=this.personalInformation.DateofBirth;
        this.bloodGroup = this.personalInformation.BloodGroup;
        this.LinkedinLink=this.personalInformation.LinkedinLink;
        this.TwitterLink=this.personalInformation.TwitterLink;
        this.nationalities=this.personalInformation.Nationality;
        this.maritalStatus=this.personalInformation.MaritalStatus;
        this.marriageDate=this.personalInformation.MarriageDate;
        this.MilitaryService= this.personalInformation.MilitaryService;
        this.ethnicRace=this.personalInformation.EthnicRace;
        this.BirthPlace = this.personalInformation.BirthPlace;
        this.OtherID=this.personalInformation.OtherID;
        const employeeLanguageData = await this.profileService.GetEmployeeLanguage(this.empid);
        this.employeelanguage = employeeLanguageData;
        document.addEventListener('refreshLanguage', this.GetEmployeeLanguageData);
        document.addEventListener('refreshEditLanguage', this.GetEmployeeEditLanguageData);
        const employeFamilyData = await this.profileService.GetFamilyMember(this.empid);
        this.employeefamily = employeFamilyData;
        document.addEventListener('refreshFamily', this.GetEmployeeFamilyData);
        document.addEventListener('refreshEditFamily', this.GetEmployeeEditFamilyData);
        flatpickr(this.datePicker, {
            dateFormat: 'd-m-Y',
            minDate: null,
            maxDate: null,
            defaultDate:this.DateofBirth,
            enableTime: false,
            enableSeconds: false,
            time_24hr: false,
            minuteIncrement: 1,
            hourIncrement: 1,
            timeFormat: 'h:i K',
            clickOpens:false,
            onClose: function(selectedDates, dateStr, instance) {
            }
        });
        this.calendarIcon.addEventListener('click', () => {
            this.datePicker._flatpickr.open();
        });
    }
    /* getOriginalData(){
           this.profileService.EmployeePersonalDetails(this.empid)
               .then(data => {
                   this.PanNumber = data.PANNumber;
                   this.SSNUID = data.SSNUID;
                   this.LinkedinLink=data.LinkedinLink;
                   this.DateOfBirth=data.DateofBirth;
                   this.bloodGroup = data.BloodGroup;
                   this.TwitterLink=data.TwitterLink;
                   this.OtherID=data.OtherID;
                   this.nationalities=data.Nationality;
                   this.maritalStatus=data.MaritalStatus;
                   this.ethnicRace=data.EthnicRace;
                   this.marriageDate=data.MarriageDate;
                   this.MilitaryService=data.MilitaryService;
                   this.BirthPlace=data.BirthPlace;
               });
           this.personalInformation.LinkedinLink=this.LinkedinLink;
           this.personalInformation.TwitterLink=this.TwitterLink;
           this.personalInformation.OtherID=this.OtherID;
           this.personalInformation.MilitaryService=this.MilitaryService;
           this.personalInformation.BirthPlace=this.BirthPlace;
     }*/
    openPopupaddlanguage(user) {
        this.dialogService.open({viewModel: AddNewLanguage, model: user});
    }
    openPopupeditlanguage(data) {
        this.dialogService.open({viewModel: Editlanguagecomponent, model: data});
    }
    showPopupaddfamily(user) {
        this.dialogService.open({viewModel: Addfamilycomponent, model: user});
    }
    showPopupeditfamily(data) {
        this.dialogService.open({viewModel: Editfamilycomponent, model: data});
    }
    cancelpersonal(){
        location.reload();
        //this.controller.cancel();
    }
    GetEmployeeLanguageData = () => {
        this.profileService.GetEmployeeLanguage(this.empid)
            .then(data => {
                this.employeelanguage = data;
                this.Languagesucess = "Language added successfully";
                this.languagemessage = true;
                setTimeout(() =>{
                    this.Languagesucess = null;
                },3000)
            });
    };
    GetEmployeeEditLanguageData = () => {
        this.profileService.GetEmployeeLanguage(this.empid)
            .then(data => {
                this.employeelanguage = data;
                this.Languagesucess = "Language updated successfully";
                this.languagemessage = true;
                setTimeout(() =>{
                    this.Languagesucess = null;
                },3000)

            });
    };
    GetEmployeeFamilyData = () => {
        this.profileService.GetFamilyMember(this.empid)
            .then(data => {
                this.employeefamily = data;
                this.Familysucess = "Family member added successfully ";
                this.familymessage = true;
                setTimeout(() =>{
                    this.Familysucess = null;
                },3000)
            });
    };
    GetEmployeeEditFamilyData = () => {
        this.profileService.GetFamilyMember(this.empid)
            .then(data => {
                this.employeefamily = data;
                this.Familysucess = "Family member updated successfully  ";
                this.familymessage = true;
                setTimeout(() =>{
                    this.Familysucess = null;
                },3000)
            });
    };
    validateBirthplace(event) {
        const inputElement = event.target;
        const inputValue = inputElement.value;
        const onlyCharactersRegex = /^[A-Za-z\s]*$/;
        if (!onlyCharactersRegex.test(inputValue)) {
            this.birthPlaceError = true;
        } else {
            this.birthPlaceError = false;
        }
    }
    validatePanNumberInput(event) {
        const inputElement = event.target;
        const inputValue = inputElement.value;
        const alphanumericRegex =/^[A-Z]{5}\d{4}[A-Z]$/;
        ///^[A-Za-z0-9]{10}$/;

        if (!alphanumericRegex.test(inputValue)) {
            if (inputValue){
                this.panNumberError = true;
            }else {
                this.panNumberError = false;
            }
        } else {
            //this.PanNumberErrors = [];
            this.panNumberError = false;
        }
    }
    validateLinkedInURL(event) {
        // const entervalue = event.target
        // const inputValue = entervalue.value;
        // const enterurl=includes('linkedin.com');
        // if (!enterurl.test(inputValue)) {
        const inputElement = event.target;
        const inputValue=inputElement.value;
        const enterurl = /linkedin\.com/;

        if (!enterurl.test(inputValue)) {
            if (inputValue){
                this.LinkedinValidationMessage = true;
            } else {
                this.LinkedinValidationMessage = false;

            }
        } else {
            this.LinkedinValidationMessage = false;
        }
    }
    validatetwitterInURL(event) {
        //const enteredURL = event.target.value;
        const inputElement = event.target;
        const inputValue=inputElement.value;
        const enterurl = /twitter\.com/;

        if (!enterurl.test(inputValue)) {
            if (inputValue) {
                this.twitterValidationMessage = true;
            }else {
                this.twitterValidationMessage = false;
            }
        } else {
            this.twitterValidationMessage = false;
        }
    }
    // validateSSNUIDInput(event) {
    //     const inputElement = event.target;
    //     const inputValue = inputElement.value;
    //     const onlyNumbersRegex = /^[0-9]*$/;
    //     if (!onlyNumbersRegex.test(inputValue)) {
    //
    //         this.ssnUidError = true;
    //     } else {
    //         this.SSNUIDErrors = [];
    //         this.ssnUidError = false;
    //     }
    // }
    validateSSNUIDInput(event) {
        const inputElement = event.target;
        const inputValue = inputElement.value;
        const onlyNumbersRegex = /^[0-9]*$/;
        if (!onlyNumbersRegex.test(inputValue)) {
            this.ssnUidError = true;
        } else {
            this.SSNUIDErrors = [];
            this.ssnUidError = false;
        }
    }
    validateUANInput(event) {
        const inputElement = event.target;
        const inputValue = inputElement.value;
        const onlyNumbersRegex = /^[0-9]*$/;
        if (!onlyNumbersRegex.test(inputValue)) {
            this.uanError = true;
        } else {
            this.uanErrors = [];
            this.uanError = false;
        }
    }

    save() {
        const refreshEvent = new CustomEvent('refreshData');
        event.preventDefault();
        this.errors = null;
        this.controllerFactory.validate()
            .then(result => {
                if (result.valid  && !this.panNumberError && !this.birthPlaceError && !this.ssnUidError && !this.LinkedinValidationMessage && !this.twitterValidationMessage) {
                    this.errors = null;
                    /*  const dateParts = this.DateOfBirth.split('-');
                      const day = parseInt(dateParts[0]);
                      const month = parseInt(dateParts[1]) - 1; // Month is 0-based in JavaScript
                      const year = parseInt(dateParts[2]);

                      const selectedDateOfBirth = new Date(year, month, day);
                      const today = new Date();
                      console.log(selectedDateOfBirth);
                      console.log(today);

  // Calculate the minimum allowed date (18 years ago from the selected date)
                      const minAllowedYear = year + 18;
                      const minAllowedDate = new Date(minAllowedYear, month, day);

                      if (today < minAllowedDate) {
                          // Show validation message because age requirement is not met
                          this.DateofBirthErrors = [{ error: { message: 'You must be at least 18 years old.' } }];
                          // this.selectedDateIsLessThan18 = true; // Show validation message
                      }else {*/
                    let data = {
                        EmployeeID: this.empid,
                        DateofBirth: this.DateOfBirth,
                        BirthPlace: this.personalInformation.BirthPlace,
                        PANNumber: this.PanNumber,
                        OtherID: this.personalInformation.OtherID,
                        EthnicRace: this.ethnicRace,
                        Nationality: this.nationalities,
                        MaritalStatus: this.maritalStatus,
                        MarriageDate: this.marriageDate,
                        BloodGroup: this.bloodGroup,
                        MilitaryService: this.personalInformation.MilitaryService,
                        SSNUID: this.SSNUID,
                        LinkedinLink: this.personalInformation.LinkedinLink,
                        TwitterLink: this.personalInformation.TwitterLink,
                        PersonID: this.personalInformation.PersonID,
                    };
                    const hasValidData = Object.values(data).some(value => value !== "--");
                    if (hasValidData ) {
                        this.profileService.SavePostPersonInformation(data)
                            .then(data => {
                                if (this.personalInformation.PersonID === 0) {
                                    this.personaldetailsmessage = " Personal details saved successfully";
                                    this.detailsmessage = true;
                                    this.profileService.EmployeePersonalDetails(this.empid)
                                        .then(data => {
                                            this.personalInformation = data;
                                            // console.log(data);
                                            document.dispatchEvent(refreshEvent);
                                        });
                                }
                                if (this.personalInformation.PersonID > 0) {
                                    this.personaldetailsmessage = " Personal details updated successfully";
                                    this.detailsmessage = true;
                                    this.profileService.EmployeePersonalDetails(this.empid)
                                        .then(data => {
                                            //console.log(data);
                                            this.personalInformation = data;
                                            document.dispatchEvent(refreshEvent);
                                        });

                                }
                            });
                    }
                }
                //}
            });

        setTimeout(() => {
            this.personaldetailsmessage = null;
        }, 3000);
    }
    async deleteLanguage(data) {
        const personID = data.PersonLanguageID;
        const result = await this.dialogService.open({
            viewModel: AlertpopupComponent,
            model: "",
        }).whenClosed(response => response);
        if (result.wasCancelled) {
            return;
        }
        await this.profileService.DeleteEmployeeLanguage(personID, this.empid);
        const employeelanguage = await this.profileService.GetEmployeeLanguage(this.empid);
        this.employeelanguage = employeelanguage;
        this.Deletesucess = "Language deleted successfully ";
        this.deletemessage = true;
        setTimeout(() =>{
            this.Deletesucess = null;
        },3000)
    }
    async deleteFamily(data) {
        this.personID = data.PersonDependantID;
        const result = await this.dialogService.open({
            viewModel: AlertpopupComponent,
            model: "",
        }).whenClosed(response => response);

        if (result.wasCancelled) {
            return;
        }
        await this.profileService.DeleteEmployeeFamily(this.personID,this.empid);
        const employeefamily= await this.profileService.GetFamilyMember(this.empid);
        this.employeefamily=employeefamily;

        this.DeleteFamilysucess = "Family member deleted successfully";
        this.deletefamilymessage = true;
        setTimeout(() =>{
            this.DeleteFamilysucess = null;
        },3000)

    }

    cancel() {
        this.dialogController.cancel();
    }

    ok() {
        this.dialogController.ok();
    }
    resetPage() {
        location.reload();
    }

}
