import {inject } from 'aurelia-framework';
import { DialogController } from 'aurelia-dialog';
import { ProfileService } from '../../../../../shared/services/profielservice';
import { JwtService } from '../../../../../shared/services/jwtservice';
import {ValidationControllerFactory, ValidationRules} from 'aurelia-validation';

@inject(DialogController,ProfileService,JwtService,ValidationControllerFactory)
export class Editlanguagecomponent {
    options = [];
    PersonID = "";
    static inject = [Element];
    isDragging = false;
    dialog;
    constructor(controller,ProfileService,JwtService,controllerFactory) {
        this.controller = controller;
        this.profileService=ProfileService;
        this.jwtService= JwtService;
        this.controllerFactory = controllerFactory.createForCurrentScope();
        ValidationRules
            .ensure('Language').required()
            .matches(/^[A-Za-z]+$/)
            .withMessage('Please enter characters only.')
            .ensure('Read').required()
            .ensure('Speak').required()
            .ensure('Write').required()
            .on(this);
    }
    attached() {
        const empid = this.jwtService.getEmployeeId();
        this.profileService.EmployeePersonalDetailsLanguage()
            .then(data => {
                this.options= Object.entries(data).map(([key, value])=>({value, key}));
            });
        this.profileService.UpdatePersonalDetailsLanguage(empid,this.personID)
            .then(data => {
                this.employeesss = data;
                this.Language = this.employeesss.Language;
                this.Write = this.employeesss.WriteCompetency;
            });
    }
    cancel() {
        this.controller.cancel();
    }
    activate(data){
        this.personID = data.PersonLanguageID;
    }
    startDragging(event) {
        if (event.button === 0) {
            this.isDragging = true;
            this.offsetX = event.clientX - this.dialogElement.getBoundingClientRect().left;
            this.offsetY = event.clientY - this.dialogElement.getBoundingClientRect().top;

            window.addEventListener('mousemove', this.drag);
            window.addEventListener('mouseup', this.stopDragging);
        }
    }

    drag = (event) => {
        if (this.isDragging) {
            const x = event.clientX - this.offsetX;
            const y = event.clientY - this.offsetY;

            this.dialogElement.style.left = `${x}px`;
            this.dialogElement.style.top = `${y}px`;
        }
    }

    stopDragging = () => {
        this.isDragging = false;
        window.removeEventListener('mousemove', this.drag);
        window.removeEventListener('mouseup', this.stopDragging);
    }
    save(){
        const refreshEvent = new CustomEvent('refreshEditLanguage');
        event.preventDefault();
        const empid = this.jwtService.getEmployeeId();
        const Language = document.getElementById("LanguageName").value;
        const ReadCompetency = document.getElementById("readdata").value;
        const SpeakCompetency = document.getElementById("speakData").value;
        const WriteCompetency = document.getElementById("writeData").value;
        this.controllerFactory.validate()
            .then(result => {
                if (result.valid ) {
                    let data = {
                        EmployeeID: empid,
                        Language: Language,
                        ReadCompetency: ReadCompetency,
                        SpeakCompetency: SpeakCompetency,
                        WriteCompetency: WriteCompetency,
                        PersonLanguageID: this.personID,
                    };
                    this.profileService.EmployeeLanguageSave(data)
                        .then(() => {
                            this.controller.cancel();
                            this.profileService.GetEmployeeLanguage(empid)
                                .then(data => {
                                    this.employeelanguage = data;
                                    document.dispatchEvent(refreshEvent); // Trigger the custom event
                                });
                        });

                }

            });
    }
}

