import {inject } from 'aurelia-framework';
import { ProfileService } from '../../../../shared/services/profielservice';
import { JwtService } from '../../../../shared/services/jwtservice';
import { DialogController } from 'aurelia-dialog';
import flatpickr from "flatpickr";
import {ValidationControllerFactory, ValidationRules} from 'aurelia-validation';
//import Scrollbar from 'smooth-scrollbar';
import {LoginService} from "../../../../shared/services/loginservice";
import { Router } from 'aurelia-router';

@inject(DialogController,ProfileService,JwtService,ValidationControllerFactory,Router,LoginService)
export class Addskillcomponent {
    showCertificationFields = false;
    showRenivalFields = false;
    showOtherFields = false;
    options = [];
    optionss = [];
    Skill = '';
    SkillError = [];
    CertificationName = '';
    selectedOption = null;
    certification = [];
    selectedCertification = null;
    DocumentName='';
    controllerFactory;
    static inject = [Element];
    isDragging = false;
    dialog;
    constructor(controller,ProfileService,JwtService,controllerFactory,router,loginService) {
        this.controller = controller;
        this.profileService=ProfileService;
        this.jwtService= JwtService;
        this.Skill = '';
        this.CertificationName = '';
        this.router = router;
        this.loginService = loginService;
        this.controllerFactory = controllerFactory.createForCurrentScope();
        ValidationRules.customRule(
          'dateError',
          () =>{
              if(this.differenceError === true){
                  return false;
              }else{
                  return true;
              }
          },
          'Valid from should be less than valid to'
      );
        ValidationRules.customRule(
        'validEmails',
        (value, obj) => {
           if (typeof value !== 'string') {
              return false;
           }
           const emails = value.split(',');
            const emailRegex = /^[^\s@]+@[^\s@]+\.(com|co|in|org|cc|net)$/;
           const invalidEmails = [];
           for (const email of emails) {
              const trimmedEmail = email.trim();
              if (!emailRegex.test(trimmedEmail)) {
                 invalidEmails.push(trimmedEmail);
              }
           }
           if (invalidEmails.length > 0) {
              obj.emailsErrors = ['Invalid email address(es)'];
              return false;
           }
           if (emails.length > 1 && value.indexOf(',') === -1) {
              obj.emailsErrors = ['Email(s) should be separated by commas'];
              return false;
           }
           obj.emailsErrors = [];
           return true;
        },
        '${$displayName} is invalid.',
        (obj) => ({
           obj
        })
     );
        ValidationRules
          .ensure('Skill').required().withMessage("Skill is required")
          .ensure('Ratings').required().withMessage("Rating is required")
          .ensure('SkillAcquiredDate').required().withMessage('Skill acquired date is required')
          .ensure('CertificationName').required().withMessage("Certification name is required")
          .ensure('ValidFrom').required().withMessage("Valid from is required")
           .ensure('ValidTo')
          .satisfiesRule('dateError')
            .ensure('EmailIDs').required().withMessage('Email id(s) field is required')
          .satisfiesRule('validEmails').withMessage('Invalid email address(es) and Email(s) should be separated by commas')
          .ensure('notifyEmp')
          .satisfies((value, object) => {
              return value || object.notifyHR || object.notifyRM || object.notifyOther;
          })
          .withMessage('Please select at least one notify field.')
            .on(this);
    }
    async bind() {
    setTimeout(()=>{       
      Scrollbar.init(document.querySelector('.addskill-scrollbar'));
    },100)
  }
  attached(){

        flatpickr(this.LatDatePicker, {
            closeOnSelect:true,
            dateFormat: 'd-m-Y',
            minDate: null,
            maxDate: null,
            defaultDate: this.dateValue,
            enableTime: false,
            enableSeconds: false,
            time_24hr: false,
            minuteIncrement: 1,
            hourIncrement: 1,
            timeFormat: 'h:i K',
           clickOpens:false,
            onClose: function (selectedDates, dateStr, instance) {
                this.LastDate=dateStr;
            }
        });
        this.lastdateIcon.addEventListener('click', () => {
            this.LatDatePicker._flatpickr.open();
        });
        flatpickr(this.AcquireddatePicker, {
            closeOnSelect:true,
            dateFormat: 'd-m-Y',
            minDate: null,
            maxDate: null,
            defaultDate: this.dateValue,
            enableTime: false,
            enableSeconds: false,
            time_24hr: false,
            minuteIncrement: 1,
            hourIncrement: 1,
            timeFormat: 'h:i K',
            clickOpens:false,
            onClose: function (selectedDates, dateStr, instance) {
                this.AcquiredDate=dateStr;
            }
        });
        this.acquiredIcon.addEventListener('click', () => {
            this.AcquireddatePicker._flatpickr.open();
        });
        this.profileService.GetSkillsRatingDropdown()
            .then(data => {
                this.options= data;
            });
    }
    cancel() {
        this.controller.cancel();
    }
    handleKeyDown(event) {
        if (event.key === 'Enter') {
            event.preventDefault();
            event.stopPropagation();
            return false;
        }
        return true;
    }

    async onSkillsSelected() {
        const trimmedSkill = this.Skill.trim(); // Remove leading and trailing spaces
        this.searcheddata = this.Skill;
        if (trimmedSkill.length > 0) {
            // Non-empty input, proceed to open the dropdown and perform filtering
            this.showEmpDropdown = true;
            try {
                const response = await this.profileService.GetSkillsDropdown(this.searcheddata);
                const data = await response;
                this.optionss = Object.entries(data).map(([key, value]) => ({ value, key }));
                const searchValue = trimmedSkill.toLowerCase();
                this.filteredOptions = this.optionss.filter(option => {
                    const optionValue = option.value.toLowerCase();
                    return optionValue.includes(searchValue);
                });
            } catch (error) {
            }
        } else {
            // Empty input or only spaces, don't open the dropdown
            this.showEmpDropdown = false;
            this.filteredOptions = [];
        }
    }


    selectSkill(feedback) {

        this.Skill = feedback.value;
        this.selectedOption = feedback;
        this.showEmpDropdown = false;
    }
    async  onCerticationSelected() {
        if (this.CertificationName) {
            this.showcertificationDropdown = true;
            try {
                const response = await this.profileService.GetCertificationDropdown(this.CertificationName);
                const data = await response;
                this.certification = Object.entries(data).map(([key, value]) => ({ value, key }));
                this.filteredOptions = this.certification.filter(option => {
                    const searchValue = this.CertificationName.toLowerCase();
                    return option.value.toLowerCase().includes(searchValue);
                });

            } catch (error) {
            }
        } else {
            this.showcertificationDropdown = false;
            this.filteredOptions = [];
        }
    }
    selectCertification(option) {
        this.selectedCertification = option;
        this.CertificationName = option.value;
        this.showcertificationDropdown = false; // Hide the dropdown
    }
    async  toggleCertificationFields(checked) {
        this.showCertificationFields = checked;
        setTimeout(()=>{
            flatpickr(this.ValidFromDatePicker, {
                closeOnSelect:true,
                dateFormat: 'd-m-Y',
                minDate: null,
                maxDate: null,
                defaultDate: this.dateValue,
                enableTime: false,
                enableSeconds: false,
                time_24hr: false,
                minuteIncrement: 1,
                hourIncrement: 1,
                timeFormat: 'h:i K',
                clickOpens:false,
                onClose: function (selectedDates, dateStr, instance) {
                    this.ValidFromDate=dateStr;
                }
            });

            this.validfromIcon.addEventListener('click', () => {
                this.ValidFromDatePicker._flatpickr.open();
            });

            flatpickr(this.ValidToDatePicker, {
                closeOnSelect:true,
                dateFormat: 'd-m-Y',
                minDate: null,
                maxDate: null,
                defaultDate: this.dateValue,
                enableTime: false,
                enableSeconds: false,
                time_24hr: false,
                minuteIncrement: 1,
                hourIncrement: 1,
                timeFormat: 'h:i K',
                clickOpens:false,
                onClose: function (selectedDates, dateStr, instance) {
                    this.ValidToDate=dateStr;
                }
            });
            this.validtoIcon.addEventListener('click', () => {
                this.ValidToDatePicker._flatpickr.open();
            },1000);
        })
    }
    toggleRenivalFields(checked) {
        this.showRenivalFields = checked;
    }
    toggleOtherFields(checked){
        this.showOtherFields = checked;
    }
    handleFileSelect(files) {
        const file = files[0];
        this.file = file;
        let reader = new FileReader();
        reader.readAsDataURL(file);
        this.DocumentName = file.name;
        reader.onload = () => {
            this.file = reader.result;
        };
    }
    openFileDialog() {
        document.getElementById('attachments').click();
    }
    removeFile() {
        this.file = null;
        this.DocumentName = '';
        const fileInput = document.getElementById('attachments');
        if (fileInput) {
            fileInput.value = '';
        }
    }
    calculateDateDifference() {
        if (this.ValidFrom && this.ValidTo) {
            const fromParts = this.ValidFrom.split('-');
            const toParts = this.ValidTo.split('-');
            const fromDate = new Date(fromParts[2], fromParts[1] - 1, fromParts[0]);
            const toDate = new Date(toParts[2], toParts[1] - 1, toParts[0]);
            const differenceInTime = toDate.getTime() - fromDate.getTime();
            const differenceInDays = Math.ceil(differenceInTime / (1000 * 3600 * 24));
            const years = Math.floor(differenceInDays / 365);
            const months = Math.floor((differenceInDays % 365) / 30);
            const days = differenceInDays % 30;
            const formattedYears = years > 0 ? `${years} Year(s)` : '';
            const formattedMonths = months > 0 ? `${months} Month(s)` : '';
            const formattedDays = days > 0 ? `${days} Day(s)` : '';
            this.dateDifference = `${formattedYears} ${formattedMonths} ${formattedDays}`;
            if (differenceInDays < 1) {
                this.differenceError = true;
            }else{
                this.differenceError = false;
            }
        }
    }
    startDragging(event) {
        if (event.button === 0) {
            this.isDragging = true;
            this.offsetX = event.clientX - this.dialogElement.getBoundingClientRect().left;
            this.offsetY = event.clientY - this.dialogElement.getBoundingClientRect().top;
            window.addEventListener('mousemove', this.drag);
            window.addEventListener('mouseup', this.stopDragging);
        }
    }

    drag = (event) => {
        if (this.isDragging) {
            const x = event.clientX - this.offsetX;
            const y = event.clientY - this.offsetY;

            this.dialogElement.style.left = `${x}px`;
            this.dialogElement.style.top = `${y}px`;
        }
    }

    stopDragging = () => {
        this.isDragging = false;
        window.removeEventListener('mousemove', this.drag);
        window.removeEventListener('mouseup', this.stopDragging);
    }
    save() {
        const refreshEvent = new CustomEvent('refreshAddSkill');
        this.controllerFactory.validate()
            .then(result => {
                if (result.valid) {
                    const empid = this.jwtService.getEmployeeId();
                    let data = {
                        EmployeeID: empid,
                        Skill: this.Skill,
                        SkillRating: this.Ratings,
                        YearofExperience: this.Experience,
                        CertificationName: this.CertificationName,
                        Comments:this.comments,
                        CertificationIND:this.havecertification,
                        IsCertificationRenewable:this.isRenewable,
                        UploadDocuments:this.file,
                        DocumentName:this.DocumentName,
                        SkillAcquired:this.SkillAcquiredDate,
                        SkillLastUsed:this.SkillLastUsed,
                        DocumentPath:this.DocumentPath,
                        NotifyEmpInd:this.notifyEmp,
                        NotifyOtherInd:this.notifyOther,
                        NotifyHRInd:this.notifyHR,
                        NotifyRMInd:this.notifyRM,
                        NotifyOtherEmailIDs:this.EmailIDs,
                        PersonSkillId:this.personskillId,
                        ValidFrom:this.ValidFrom,
                        ValidTo:this.ValidTo,
                        RemidDaysBeforeExpiryDate:this.remaindBefore,


                    };
                    this.profileService.GetEmployeeSkills(empid)
                        .then(existingSkills => {
                            if (existingSkills.some(skill => skill.Skill === this.Skill)) {
                                this.errorMessage = 'Skill already exists.';
                                const errorMessages = document.querySelectorAll(".error-messages");
                                if (errorMessages.length > 0) {
                                    errorMessages[0].scrollIntoView({ behavior: "smooth" });
                                }
                            } else {
                                this.profileService.SaveEmployeeSkills(data)
                                    .then(() => {
                                        this.controller.cancel();
                                        this.profileService.GetEmployeeSkills(empid)
                                            .then(data => {
                                                this.employeeskills = data;
                                                document.dispatchEvent(refreshEvent); // Trigger the custom event
                                            });
                                    });
                            }
                        });
                }
                else{
                    const errorMessages = document.querySelectorAll(".error-messages");
                if (errorMessages.length > 0) {
                    errorMessages[0].scrollIntoView({ behavior: "smooth" });
                }}
            });
    }
}
