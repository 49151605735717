import {inject,bindable, bindingMode} from 'aurelia-framework';
import { DialogController } from 'aurelia-dialog';
import flatpickr from 'flatpickr';
import { ProfileService } from '../../../../../shared/services/profielservice';
import { JwtService } from '../../../../../shared/services/jwtservice';
import {ValidationControllerFactory, ValidationRules} from 'aurelia-validation';
import {LoginService} from "../../../../../shared/services/loginservice";
import { Router } from 'aurelia-router';

@inject(DialogController,ProfileService,JwtService,ValidationControllerFactory,Router,LoginService)
export class Addfamilycomponent {
    @bindable({ defaultBindingMode: bindingMode.twoWay }) dateValue;
    @bindable employee;
    options=[];
    lifetypes=[];
    isSearchActive = false;
    results = [];
    searchQuery = '';
    dropdownOptions = [];
    showEmpDropdown = true;
    static inject = [Element];
    isDragging = false;
    dialog;
    offsetX = 0;
    offsetY = 0;
    constructor(controller,ProfileService,JwtService,controllerFactory,router,loginService) {
        this.controller = controller;
        this.profileService=ProfileService;
        this.jwtService= JwtService;
        this.dropdownOptions = [];
        this.router = router;
        this.loginService = loginService;

        this.controllerFactory = controllerFactory.createForCurrentScope();
        ValidationRules
            .ensure('Name').required()
            .ensure('Relationship').required()
            .ensure('LifeEvent').required().withMessage("Life event is required")
            .ensure('DateofBirth').required().withMessage("Date of birth is required")
            .on(this);
    }
    attached() {

        flatpickr(this.datePicker, {
            closeOnSelect:true,
            dateFormat: 'd-m-Y',
            minDate: null,
            maxDate: null,
            defaultDate: this.dateValue,
            enableTime: false,
            enableSeconds: false,
            time_24hr: false,
            minuteIncrement: 1,
            hourIncrement: 1,
            timeFormat: 'h:i K',
            clickOpens:false,
            onClose: function (selectedDates, dateStr, instance) {
                this.DateofBirth=dateStr;
            }
        });
        this.calendarIcon.addEventListener('click', () => {
            this.datePicker._flatpickr.open();
        });
        this.isLoading = true;
        setTimeout(() => {
            this.isLoading = false;
        }, 3000);
        this.profileService.EmployeeFamilyRelationship()
            .then(data => {
                this.options= data;
            });
        this.profileService.EmployeeFamilyLifeeventtypes()
            .then(data => {
                this.lifetypes= data;
            });
    }
    toggleSearchBar() {
        this.isSearchActive = !this.isSearchActive;
    }
    async onEmployeeSelected() {
        if (this.searchQuery) {
            this.showEmpDropdown = true;
            try {
                const response = await this.profileService.EmployeeNamesForDropdown(this.searchQuery);
                const data = await response;
                const filterOption = this.searchQuery.toLowerCase();
                this.dropdownOptions = data.filter(option => option.EmployeeName.toLowerCase().includes(filterOption));
                if (this.dropdownOptions.length === 0) {
                    const errorInfo = {
                        error: {
                            message: "Please select valid employee"
                        }
                    };
                    this.NameError = [errorInfo];
                } else {
                    this.NameError = [];
                }
            } catch (error) {
                console.error(error);
            }
        } else {
            this.showEmpDropdown = false;
            this.dropdownOptions = [];
        }
    }
    selectEmployee(EmpOption) {
        const modifiedOption = { ...EmpOption, ID: EmpOption.EmployeeID };
        this.employeeName = EmpOption.EmployeeID;
        this.searchQuery = modifiedOption.EmployeeName;
        this.showEmpDropdown = false;
        this.selectedEmployee = modifiedOption;
    }
    cancel() {
        this.controller.cancel();
    }
    startDragging(event) {
        if (event.button === 0) {
            this.isDragging = true;
            this.offsetX = event.clientX - this.dialogElement.getBoundingClientRect().left;
            this.offsetY = event.clientY - this.dialogElement.getBoundingClientRect().top;

            window.addEventListener('mousemove', this.drag);
            window.addEventListener('mouseup', this.stopDragging);
        }
    }

    drag = (event) => {
        if (this.isDragging) {
            const x = event.clientX - this.offsetX;
            const y = event.clientY - this.offsetY;

            this.dialogElement.style.left = `${x}px`;
            this.dialogElement.style.top = `${y}px`;
        }
    }

    stopDragging = () => {
        this.isDragging = false;
        window.removeEventListener('mousemove', this.drag);
        window.removeEventListener('mouseup', this.stopDragging);
    }
    async save() {
        const refreshEvent = new CustomEvent('refreshFamily');
        event.preventDefault();
        let checkedorganization = false;
        let checkeddpendent = false;
        let lifeeventdate = "0001-00-00";
        let PersonDependantID = "0";
        let EmployeeName;
        let EmployeeID;
        if (this.organization === true) {
            EmployeeID = this.employeeName;
            checkeddpendent = true;
            await this.profileService.GetEmployeeNamesForGrid(EmployeeID)
                .then(data => {
                    this.EmployeeName = data.EmployeeName;
                });
        } else {
            this.EmployeeName = this.Name;
        }
        if (this.LifeEventTypeDate) {
            lifeeventdate = true;
        }
        if (this.PersonDependantID) {
            PersonDependantID = true;
        }
        this.controllerFactory.validate()
            .then(result => {
                if (result.valid) {
                    const empid = this.jwtService.getEmployeeId();
                    let data = {
                        EmployeeID: empid,
                        Name: this.EmployeeName,
                        EmployeeDependentConfigID: this.Relationship,
                        DateofBirth: this.DateofBirth,
                        LifeEventType: this.LifeEvent,
                        LifeEventTypeDate: lifeeventdate,
                        SameOrganizationIND: this.organization,
                        DependentIND: this.dependent,
                        PersonDependantID: PersonDependantID,
                    };
                    const EmployeeFamilyValidatecheck={
                        EmployeeID:empid,
                        Name:this.EmployeeName,
                    };
                    this.profileService.EmployeeNameAvoidDuplicate(EmployeeFamilyValidatecheck).then(employeeName => {
                        if (employeeName === 1) {
                            this.errorMessage=true;
                            this.errorMessage = 'Name already exists.';
                            setTimeout(()=>{this.errorMessage=null},5000);
                        } else {
                            this.profileService.EmployeeFamilyMemberSave(data).then(() => {
                                this.controller.cancel();
                                this.profileService.GetFamilyMember(empid)
                                    .then(data => {
                                        this.employeefamily = data;
                                        document.dispatchEvent(refreshEvent); // Trigger the custom event
                                        });
                                });
                            }
                        });
                }
            });
    }
}
